module.exports = {
  // global
  fu_zhi_cheng_gong: 'コピー成功!',
  zan_wu_fu_zhi_nei_rong: 'コピーコンテンツが一時的にありません!',
  li_shi_ji_lu: '履歴',

  // login
  wei_xin_deng_lu: 'ウィーチャットログイン',
  qing_guan_zhu_wei_xin_gong_zhong_hao: 'ウィチャットの公式アカウントに注目してください',
  guan_zhu_cheng_gong: '成功への関心!',

  // header
  mon: 'モンゴル語',
  zh: '中国語',
  fan_yi: '翻訳',
  zhuan_huan: 'へんかん',
  meng_gu_wen_ocr_shi_bie: 'OCR識別',
  yu_yin_shi_bie: '音声認識',

  // 记录
  fan_yi_ji_lu: '翻訳レコード',
  OCR_shi_bie_ji_lu: 'OCR 識別レコード',
  yu_yin_shi_bie_ji_lu: '音声認識記録',
  shi_jian: '時間',
  yuan_wen: 'テキスト',
  yi_wen: '翻訳文',
  fang_xiang: '方向',
  wen_jian: 'ファイル',
  shi_bie_jie_guo: '認識結果',

  // 翻译
  zi_dong_jian_ce: '自動',
  qing_xuan_ze: '選択してください',
  zhong_wen: '中国語',
  meng_wen: 'モンゴル語',
  ying_wen: '英語',
  ri_wen: '日本語',
  xi_li_er_wen: 'キリル文字',
  dang_qian_zi_shu_yi_chao_chu_xian_ding_zi_shu: '現在の文字数は修飾文字数を超えています!',
  zhi_yun_xu_zhuan_huan_biao_zhun_bian_ma_wen_ben: '標準コードテキストのみ入力可能！',

  // OCR 识别
  shang_chuan: 'アップロード',
  zhuan_huan: 'へんかん',
  shi_bie: '識別',
  jiang_wen_jian_tuo_zhuai_dao_ci_chu_huo: 'ファイルをここにドラッグするか、',
  dian_ji_shang_chuan: 'アップロードをクリック',
  qing_kong: 'クリアランス',
  que_ding_shan_chu_gai_tu_pian: '画像を削除してよろしいですか？',
  que_ding: 'を選択して、',
  qu_xiao: 'キャンセル',
  shan_chu_cheng_gong: '削除に成功しました！',
  dang_qian_xian_zhi_xuan_ze_10_ge_wen_jian: '現在の制限は1つのファイルを選択して、',
  ben_ci_xuan_ze_le: '今回は',
  ge_wen_jian: '個のファイル、',
  gong_xuan_ze_le: '選択しました',
  shang_chuan_tu_pian_da_xiao_bu_neng_chao_guo: 'アップロードされた画像のサイズは、',

  // 语音识别
  yu_yin_wen_jian: '音声ファイル',
  shi_bie_jie_guo: '認識結果',
  shi_bie_zhong: '認識中',
  que_ding_shan_chu: '削除してよろしいですか',
  shan_chu: '削除＃サクジョ＃',
  qing_shi_yong_mai_ke_feng: 'まずこのWebページでマイクを使用させてください',
  lu_yin_shang_chuan_shi_bai: '録音アップロードに失敗しました！',
  shi_bie_shi_bai: '認識に失敗しました！'


}

