module.exports = {
  // header
  fu_zhi_cheng_gong: 'Хуулбарсан!', // 复制成功 
  zan_wu_fu_zhi_nei_rong: 'Хуулбар хараахан байхгүй !',    //  暂无复制内容
  li_shi_ji_lu: 'Түүхэн бүртгэл',   // 历史记录

  wei_xin_deng_lu: ' WeChat нэвтрэх',    // 微信登录
  qing_guan_zhu_wei_xin_gong_zhong_hao: ' WeChat олон нийтийн дансанд анхаарлаа хандуулсугай',   //请关注微信公众号
  guan_zhu_cheng_gong: 'Анхаарлаа хандуулсан!',   //关注成功

  // tab
  mon: 'Монгол',   //  蒙文 
  zh: 'Хятад',   //中文
  fan_yi: 'Орчуулга',   //翻译
  zhuan_huan: 'Хөрвүүлэх',   //转换
  meng_gu_wen_ocr_shi_bie: 'OCR-ийн тодорхойлолт',    //OCR识别
  yu_yin_shi_bie: ' Хэл яриаг таних',    //语音识别

  // 翻译
  fan_yi_ji_lu: 'Орчуулгын бүртгэл',   //翻译记录
  OCR_shi_bie_ji_lu: 'ОСR-ийн тодорхойлолтын бүртгэл',    //OCR 识别记录
  yu_yin_shi_bie_ji_lu: 'Хэл яриаг таних бичлэг',    //语音识别记录
  shi_jian: 'Цаг хугацаа',    //时间
  yuan_wen: 'Эх бичиг',   //原文
  yi_wen: 'Орчуулгын бичиг',   //译文
  fang_xiang: 'Чиглэл',   //方向
  wen_jian: 'файл',   //文件
  shi_bie_jie_guo: 'Тодорхойлсан дүн',    //识别结果

  // 翻译
  // zi_dong_jian_ce: 'Автоматаар илрүүлэх',    //自动检测
  zi_dong_jian_ce: 'Авто илрүүлэт',    //自动检测
  qing_xuan_ze: 'Сонгоно уу',    //请选择
  zhong_wen: 'Хятад',    //中文
  meng_wen: 'Монгол',    //蒙文
  ying_wen: 'Англи',   //英文
  ri_wen: 'Япон',    //日文
  xi_li_er_wen: 'Кирилл', //西里尔文
  dang_qian_zi_shu_yi_chao_chu_xian_ding_zi_shu: 'Одоогийн үгийн тоо нь хязгаартай үгийн тооноос илүү!',   //当前字数以超出限定字数!
  zhi_yun_xu_zhuan_huan_biao_zhun_bian_ma_wen_ben: 'Зөвхөн стандарт кодын текстийг оруулдаг!',

  // OCR
  shang_chuan: 'Татаж оруулах',    //上传
  zhuan_huan: 'Хөрвүүлэх',   //转换
  shi_bie: 'Тодорхойлох',   //识别
  jiang_wen_jian_tuo_zhuai_dao_ci_chu_huo: 'Файлыг энд татаж оруулах, эсвэл',   //将文件拖拽到此处,或
  dian_ji_shang_chuan: 'Довшиж оруулах',    //点击上传
  qing_kong: 'Хоослох',    //清空
  que_ding_shan_chu_gai_tu_pian: 'Энэ зургийг хаяхыг хүсэж байгаа уу?',    //确定移除该图片？
  que_ding: 'Тийм',   //确定
  qu_xiao: 'Цуцлах',    //取消
  shan_chu_cheng_gong: 'Арилгасан!',    //删除成功！
  dang_qian_xian_zhi_xuan_ze_10_ge_wen_jian: 'Одоохонд 1 Файлыг сонгох',   //当前限制选择 1 个文件，
  ben_ci_xuan_ze_le: 'Энэ удаад сонгосон нь',    //本次选择了
  ge_wen_jian: 'файл',   //个文件，
  gong_xuan_ze_le: 'Нийт сонгосон нь',    //共选择了
  shang_chuan_tu_pian_da_xiao_bu_neng_chao_guo: 'Татаж оруулах зургийн хэмжээ хэтэрч болохгүй',  //上传图片大小不能超过

  // 语音识别
  yu_yin_wen_jian: 'Хэл яриагийн Файл',   //语音文件
  shi_bie_jie_guo: 'Тодорхойлсан дүн',    //识别结果
  shi_bie_zhong: 'Тодорхойлох',    //识别中
  que_ding_shan_chu: 'Арилгах ёстой',   //确定删除
  shan_chu: 'Арилгах',    //删除
  qing_shi_yong_mai_ke_feng: 'Цахим хуудаст микрофон ашиглахыг зөвшөөрөх',    //请先允许该网页使用麦克风
  lu_yin_shang_chuan_shi_bai: 'Бичлэг бүтэлгүйтсэн!',   //录音上传失败！
  shi_bie_shi_bai: 'Тодорхойлолт бүтэлгүйтсэн!'    //识别失败！
}

