module.exports = {
  // global
  fu_zhi_cheng_gong: 'ᠺᠣᠫᠢᠳᠠᠭᠰᠠᠨ!',
  zan_wu_fu_zhi_nei_rong: 'ᠺᠣᠫᠢᠳᠠᠠᠬᠤ ᠠᠭᠤᠯᠭ᠎ᠠ᠎ᠦᠭᠡᠢ!',
  li_shi_ji_lu: 'ᠣᠷᠴᠢᠭᠤᠯᠤᠭᠰᠠᠨ ᠲᠡᠮᠳᠡᠭᠯᠡᠯ',
  
  // login
  wei_xin_deng_lu: 'ᠸᠢᠴᠠᠲ᠎ᠤᠨ ᠲᠡᠮᠳᠡᠭᠯᠡᠯ',
  qing_guan_zhu_wei_xin_gong_zhong_hao: 'ᠸᠢᠴᠠᠲ᠎ᠤᠨ ᠨᠡᠢᠲᠡ᠎ᠶᠢᠨ ᠳ᠋ᠤᠭᠠᠷ᠎ᠢ ᠠᠩᠬᠠᠷᠤᠭᠠᠷᠠᠢ',
  guan_zhu_cheng_gong: 'ᠠᠩᠬᠠᠷᠪᠠ!',

  // header
  mon: 'ᠮᠣᠩᠭᠤᠯ',
  zh: 'ᠬᠢᠲᠠᠳ',
  fan_yi: 'ᠣᠷᠴᠢᠭᠤᠯᠬᠤ',
  // meng_gu_wen_ocr_shi_bie: 'ᠮᠣᠩᠭᠤᠯ ᠬᠡᠯᠡᠨ᠎ᠦ OCR ᠢᠯᠭᠠᠨ ᠲᠠᠨᠢᠬᠤ',
  meng_gu_wen_ocr_shi_bie: 'OCR ᠢᠯᠭᠠᠨ ᠲᠠᠨᠢᠬᠤ',
  yu_yin_shi_bie: 'ᠠᠪᠢᠶ᠎ᠠ ᠢᠯᠭᠠᠨ ᠲᠠᠨᠢᠬᠤ',

  // 记录
  fan_yi_ji_lu: 'ᠣᠷᠴᠢᠭᠤᠯᠭ᠎ᠠ ᠎ᠶᠢᠨ ᠲᠡᠮᠳᠡᠭᠯᠡᠯ',
  OCR_shi_bie_ji_lu: 'OCR ᠢᠯᠭᠠᠬᠤ ᠲᠡᠮᠳᠡᠭᠯᠡᠯ',
  yu_yin_shi_bie_ji_lu: 'ᠠᠪᠢᠶ᠎ᠠ ᠢᠯᠭᠠᠨ ᠲᠠᠨᠢᠬᠤ ᠲᠡᠮᠳᠡᠭᠯᠡᠯ',
  shi_jian: 'ᠴᠠᠭ',
  yuan_wen: 'ᠤᠭ ᠵᠣᠬᠢᠶᠠᠯ',
  yi_wen: 'ᠣᠷᠴᠢᠭᠤᠯᠭ᠎ᠠ',
  fang_xiang: 'ᠴᠢᠭᠯᠡᠯ',
  wen_jian: 'ᠪᠢᠴᠢᠭ ᠮᠠᠲᠧᠷᠢᠶᠠᠯ',
  shi_bie_jie_guo: 'ᠢᠯᠭᠠᠨ ᠲᠠᠨᠢᠭᠰᠠᠨ ᠦᠷ᠎ᠡ ᠳ᠋ᠦᠩ',
    
  // 翻译
  zi_dong_jian_ce: 'ᠠᠦ᠋ᠲ᠋ᠣ᠋',
  qing_xuan_ze: 'ᠰᠣᠩᠭᠤᠭᠠᠷᠠᠢ',
  zhong_wen: 'ᠬᠢᠲᠠᠳ',
  meng_wen: 'ᠮᠣᠩᠭᠤᠯ',
  ying_wen: 'ᠠᠩᠭᠯᠢ',
  ri_wen: 'ᠶᠠᠫᠤᠨ',
  xi_li_er_wen: 'ᠺᠢᠷᠢᠯ',
  dang_qian_zi_shu_yi_chao_chu_xian_ding_zi_shu: 'ᠣᠳᠣᠬᠢ ᠦᠰᠦᠭ᠎ᠦᠨ ᠲᠣᠭ᠎ᠠ᠎ᠨᠢ ᠬᠢᠵᠠᠭᠠᠷᠯᠠᠭᠰᠠᠨ ᠲᠣᠭ᠎ᠠ᠎ᠠᠴᠠ ᠬᠡᠲᠦᠷᠡᠪᠡ!',
  zhi_yun_xu_zhuan_huan_biao_zhun_bian_ma_wen_ben: 'ᠵᠥᠪᠬᠡᠨ ᠪᠠᠷᠢᠮᠵᠢᠶ᠎ᠠ ᠺᠣᠳ᠋᠎ᠢ ᠪᠢᠴᠢᠬᠦ᠎ᠶᠢ ᠵᠥᠪᠱᠢᠶᠡᠷᠡᠨ᠎ᠡ᠃',

  // OCR 识别
  shang_chuan: 'ᠳᠠᠮᠵᠢᠭᠤᠯᠬᠤ',
  zhuan_huan: 'ᠬᠤᠪᠢᠷᠠᠭᠤᠯᠬᠤ',
  shi_bie: 'ᠢᠯᠭᠠᠬᠤ',
  jiang_wen_jian_tuo_zhuai_dao_ci_chu_huo: 'ᠪᠢᠴᠢᠭ ᠮᠠᠲᠧᠷᠢᠶᠠᠯ᠎ᠢ ᠡᠨᠳᠡ ᠲᠠᠲᠠᠵᠦ ᠠᠪᠠᠴᠢᠬᠤ ᠪᠤᠶᠤ',
  dian_ji_shang_chuan: 'ᠳᠠᠷᠤᠵᠤ ᠳᠡᠭᠡᠭᠱᠢ ᠳᠠᠮᠵᠢᠭᠤᠯᠬᠤ',
  qing_kong: 'ᠴᠡᠪᠡᠷᠯᠡᠬᠦ',
  que_ding_shan_chu_gai_tu_pian: 'ᠲᠣᠰ ᠵᠢᠷᠤᠭ᠎ᠢ ᠱᠢᠯᠵᠢᠭᠦᠯᠬᠦ?',
  que_ding: 'ᠲᠡᠢᠮᠦ',
  qu_xiao: 'ᠪᠤᠴᠠᠬᠤ',
  shan_chu_cheng_gong: 'ᠬᠠᠰᠤᠵᠤ ᠳᠡᠢᠯᠦᠭᠰᠡᠨ!',
  dang_qian_xian_zhi_xuan_ze_10_ge_wen_jian: 'ᠣᠳᠣᠬᠠᠨ᠎ᠳᠤ 1 ᠪᠢᠴᠢᠭ ᠮᠠᠲᠧᠷᠢᠶᠠᠯ᠎ᠢ ᠰᠣᠩᠭᠤᠬᠤ᠎ᠶᠢ ᠬᠢᠵᠠᠭᠠᠷᠯᠠᠵᠤ ᠪᠠᠢᠨ᠎ᠠ',
  ben_ci_xuan_ze_le: 'ᠡᠨᠡ ᠤᠳᠠᠭ᠎ᠠ ᠰᠣᠩᠭᠤᠭᠰᠠᠨ',
  ge_wen_jian: 'ᠪᠢᠴᠢᠭ ᠮᠠᠲᠧᠷᠢᠶᠠᠯ,',
  gong_xuan_ze_le: 'ᠨᠡᠢᠲᠡ ᠰᠣᠩᠭᠤᠭᠰᠠᠨ',
  shang_chuan_tu_pian_da_xiao_bu_neng_chao_guo: 'ᠳᠠᠮᠵᠢᠭᠤᠯᠬᠤ ᠵᠢᠷᠤᠭ ᠎ᠨᠢ ᠬᠠᠮᠤᠭ ᠶᠡᠬᠡ ᠳ᠋ᠦ ᠎ᠪᠡᠡ ',

  // 语音识别
  yu_yin_wen_jian: 'ᠱᠢᠩᠭᠡᠭᠡᠯᠲᠡ',
  shi_bie_jie_guo: 'ᠢᠯᠭᠠᠨ ᠲᠠᠨᠢᠭᠰᠠᠨ ᠦᠷ᠎ᠡ ᠳ᠋ᠦᠩ᠃',
  shi_bie_zhong: 'ᠢᠯᠭᠠᠨ ᠲᠠᠨᠢᠵᠤ ᠪᠠᠢᠨ᠎ᠠ',
  que_ding_shan_chu: 'ᠡᠭᠦᠨ᠎ᠢ ᠦᠭᠡᠢᠰᠭᠡᠨ᠎ᠡ ',
  shan_chu: 'ᠲᠡᠢᠮᠦ',
  qing_shi_yong_mai_ke_feng: 'ᠤᠷᠢᠳᠠᠪᠠᠷ ᠡᠨᠡ ᠰᠦᠯᠵᠢᠶᠡᠨ ᠨᠢᠭᠤᠷ᠎ᠲᠤ ᠮᠢᠺᠷᠣᠹᠣᠨ ᠬᠡᠷᠡᠭᠯᠡᠬᠦ᠎ᠶᠢ ᠵᠥᠪᠱᠢᠶᠡᠷᠡᠭᠡᠷᠡᠢ᠃',
  lu_yin_shang_chuan_shi_bai: 'ᠳᠠᠭᠤ ᠱᠢᠩᠭᠡᠭᠡᠯᠲᠡ᠎ᠪᠡᠷ ᠳᠠᠮᠵᠢᠭᠤᠯᠵᠤ ᠳᠡᠢᠯᠦᠭᠰᠡᠨ᠎ᠦᠭᠡᠢ!',
  shi_bie_shi_bai: 'ᠢᠯᠭᠠᠨ ᠲᠠᠨᠢᠵᠤ ᠳᠡᠢᠯᠦᠭᠰᠡᠨ᠎ᠦᠭᠡᠢ!'
}