module.exports = {
  // global
  fu_zhi_cheng_gong: 'Copy!',
  zan_wu_fu_zhi_nei_rong: 'No copy content!',
  li_shi_ji_lu: 'History',

  // login
  wei_xin_deng_lu: 'WeChat login',
  qing_guan_zhu_wei_xin_gong_zhong_hao: 'Please pay attention to wechat official account!',
  guan_zhu_cheng_gong: 'Focus on success!',

  // header
  mon: 'Mongolian',
  zh: 'Chinese',
  fan_yi: 'Translate',
  zhuan_huan: 'Transformation',
  meng_gu_wen_ocr_shi_bie: 'OCR Recognition',
  yu_yin_shi_bie: 'Speech Recognition',

  // 记录
  fan_yi_ji_lu: 'Translation Record',
  OCR_shi_bie_ji_lu: 'OCR Identification Record',
  yu_yin_shi_bie_ji_lu: 'Speech Recognition Record',
  shi_jian: 'Time',
  yuan_wen: 'Original Text',
  yi_wen: 'Translation',
  fang_xiang: 'Direction',
  wen_jian: 'File',
  shi_bie_jie_guo: 'Identification Results',

  // 翻译
  zi_dong_jian_ce: 'Auto',
  qing_xuan_ze: 'choose',
  zhong_wen: 'Chinese',
  meng_wen: 'Mongolian',
  ying_wen: 'English',
  ri_wen: 'Japanese',
  xi_li_er_wen: 'Cyrillic',
  dang_qian_zi_shu_yi_chao_chu_xian_ding_zi_shu: 'The current number of words exceeds the limit!',
  zhi_yun_xu_zhuan_huan_biao_zhun_bian_ma_wen_ben: 'Only standard encoded text is allowed!',

  // OCR 识别
  shang_chuan: 'Upload',
  zhuan_huan: 'Transformation',
  shi_bie: 'Distinguish',
  jiang_wen_jian_tuo_zhuai_dao_ci_chu_huo: 'Drag the file here, or',
  dian_ji_shang_chuan: 'click upload',
  qing_kong: 'clear',
  que_ding_shan_chu_gai_tu_pian: 'Are you sure you want to remove this picture?',
  que_ding: 'Confirm',
  qu_xiao: 'Cancel',
  shan_chu_cheng_gong: 'Deletion succeeded!',
  dang_qian_xian_zhi_xuan_ze_10_ge_wen_jian: 'The current limit is 1 file,',
  ben_ci_xuan_ze_le: 'Selected this time',
  ge_wen_jian: 'Files,',
  gong_xuan_ze_le: 'A total of',
  shang_chuan_tu_pian_da_xiao_bu_neng_chao_guo: 'Maximum size of uploaded pictures',

  // 语音识别
  yu_yin_wen_jian: 'Voice File',
  shi_bie_jie_guo: 'Identification Results',
  shi_bie_zhong: 'Identifying',
  que_ding_shan_chu: 'Confirm to delete',
  shan_chu: 'Delete',
  qing_shi_yong_mai_ke_feng: 'Please allow this webpage to use a microphone first!',
  lu_yin_shang_chuan_shi_bai: 'Recording upload failed!',
  shi_bie_shi_bai: 'Recognition failed!'

}

