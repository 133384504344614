module.exports = {
  // global
  fu_zhi_cheng_gong: '复制成功!',
  zan_wu_fu_zhi_nei_rong: '暂无复制内容!',
  li_shi_ji_lu: '历史记录',

  // login
  wei_xin_deng_lu: '微信登录',
  qing_guan_zhu_wei_xin_gong_zhong_hao: '请关注微信公众号',
  guan_zhu_cheng_gong: '关注成功!',

  // header
  mon: '蒙文',
  zh: '中文',
  fan_yi: '翻译',
  zhuan_huan: '转换',
  meng_gu_wen_ocr_shi_bie: 'OCR识别',
  yu_yin_shi_bie: '语音识别',

  // 记录
  fan_yi_ji_lu: '翻译记录',
  OCR_shi_bie_ji_lu: 'OCR 识别记录',
  yu_yin_shi_bie_ji_lu: '语音识别记录',
  shi_jian: '时间',
  yuan_wen: '原文',
  yi_wen: '译文',
  fang_xiang: '方向',
  wen_jian: '文件',
  shi_bie_jie_guo: '识别结果',

  // 翻译
  zi_dong_jian_ce: '自动检测',
  qing_xuan_ze: '请选择',
  zhong_wen: '中文',
  meng_wen: '蒙文',
  ying_wen: '英文',
  ri_wen: '日文',
  xi_li_er_wen: '西里尔文',
  dang_qian_zi_shu_yi_chao_chu_xian_ding_zi_shu: '当前字数以超出限定字数!',
  zhi_yun_xu_zhuan_huan_biao_zhun_bian_ma_wen_ben: '只允许输入标准编码文本',


  // OCR 识别
  shang_chuan: '上传',
  zhuan_huan: '转换',
  shi_bie: '识别',
  jiang_wen_jian_tuo_zhuai_dao_ci_chu_huo: '将文件拖拽到此处,或',
  dian_ji_shang_chuan: '点击上传',
  qing_kong: '清空',
  que_ding_shan_chu_gai_tu_pian: '确定移除该图片？',
  que_ding: '确定',
  qu_xiao: '取消',
  shan_chu_cheng_gong: '删除成功！',
  dang_qian_xian_zhi_xuan_ze_10_ge_wen_jian: ' 当前限制选择 1 个文件，',
  ben_ci_xuan_ze_le: '本次选择了',
  ge_wen_jian: '个文件，',
  gong_xuan_ze_le: '共选择了',
  shang_chuan_tu_pian_da_xiao_bu_neng_chao_guo: '上传图片大小不能超过',

  // 语音识别
  yu_yin_wen_jian: '语音文件',
  shi_bie_jie_guo: '识别结果',
  shi_bie_zhong: '识别中',
  que_ding_shan_chu: '确定删除',
  shan_chu: '删除',
  qing_shi_yong_mai_ke_feng: '请先允许该网页使用麦克风',
  lu_yin_shang_chuan_shi_bai: '录音上传失败！',
  shi_bie_shi_bai: '识别失败！'

}

